export const constants = {
  columnWidth: 600,
  testAps: [
    '6c52d174-4f62-11e7-aebe-67a303eb0640',
    '862333db-31cf-444c-b8ea-021c640c7a44',
    '0aba83f3-d515-4806-bc14-838f8f6d0fe6',
    '96efab5c-8bfc-11e7-a848-2b4184d2c8eb',
  ],
  mobileViewMaxWidth: 999,
  minWidthToShowTitle: 1040,
}
